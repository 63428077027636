import { createRouter, createWebHashHistory } from 'vue-router'

const routes = [
  {
    path: '/:accessName',
    name: 'Home',
    component: () => import(/* webpackChunkName: "about" */ '../views/home/Home.vue')
  },
  {
    path: '/components/shareNewsDetail/:accessName/:newsId',
    name: 'NewsDetail',
    component: () => import(/* webpackChunkName: "about" */ '../views/components/ShareNewsDetail.vue')
  },
  {
    path: '/search',
    name: 'Search',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/search/Search.vue')
  },
  {
    path: '/searchDetail/:newsId/:accessName',
    name: 'SearchDetail',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/search/SearchDetail.vue')
  },
  {
    path: '/newspaperList',
    name: 'NewspaperList',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/newspaperList/NewspaperList.vue')
  }
  // {
  // path: '/about',
  // name: 'About',
  // route level code-splitting
  // this generates a separate chunk (about.[hash].js) for this route
  // which is lazy-loaded when the route is visited.
  // component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  // }
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

export default router
