import { createStore } from 'vuex'

let defaultAccessName = ''
let defaultCName = ''
let defaultSearchText = ''
try {
  if (localStorage.accessName) {
    defaultAccessName = localStorage.accessName
  } else {
    defaultAccessName = ''
  }
  if (localStorage.cName) {
    defaultCName = localStorage.cName
  } else {
    defaultCName = ''
  }
  if (localStorage.searchText) {
    defaultSearchText = localStorage.searchText
  } else {
    defaultSearchText = ''
  }
} catch (e) {}
export default createStore({
  state: {
    accessName: defaultAccessName,
    cName: defaultCName,
    searchText: defaultSearchText
  },
  mutations: {
    getNewspaper (state, str) {
      state.accessName = str
      try {
        localStorage.accessName = str
      } catch (e) {}
    },
    getCName (state, str) {
      state.cName = str
      try {
        localStorage.cName = str
      } catch (e) {}
    },
    getSearchText (state, str) {
      state.searchText = str
      try {
        localStorage.searchText = str
      } catch (e) {}
    }
  },
  actions: {
    getNewspaper (store, str) {
      store.commit('getNewspaper', str)
    },
    getCName (store, str) {
      store.commit('getCName', str)
    },
    getSearchText (store, str) {
      store.commit('getSearchText', str)
    }
  },
  modules: {
  }
})
