<template>
  <a-locale-provider :locale="zhCN">
    <router-view/>
  </a-locale-provider>
</template>
<script>
import zhCN from 'ant-design-vue/lib/locale-provider/zh_CN'
import moment from 'moment'
moment.locale('zh-cn')
export default {
  setup () {
    return {
      zhCN
    }
  }
}
</script>
